


































































import { Vue, Component, Mixins } from 'vue-property-decorator'
import _ from 'lodash'
import MyCommon from '@/mixins/common'

// @ts-ignore
import MyDvPage from '$ui/dv/packages/my-dv-page'
// @ts-ignore
import MyDvStarry from '$ui/dv/packages/my-dv-starry'
// @ts-ignore
import MyDvHeader6 from '$ui/dv/packages/my-dv-header6'
// @ts-ignore
import MyDvHeader5 from '$ui/dv/packages/my-dv-header5'
// @ts-ignore
import MyDvAdorn5 from '$ui/dv/packages/my-dv-adorn5'
// @ts-ignore
import MyDvAdorn3 from '$ui/dv/packages/my-dv-adorn3'
// @ts-ignore
import MyDvAdorn1 from '$ui/dv/packages/my-dv-adorn1'
// @ts-ignore
import MyDvMenu from '$ui/dv/packages/my-dv-menu'
// @ts-ignore
import MyDvTitle from '$ui/dv/packages/my-dv-title'
// @ts-ignore
import MyDvText from '$ui/dv/packages/my-dv-text'
// @ts-ignore
import MyDvDate from '$ui/dv/packages/my-dv-date'
// @ts-ignore
import MyDvBox from '$ui/dv/packages/my-dv-box'

import icon from '@/assets/icon'

@Component({
  components: {
    MyDvPage,
    MyDvStarry,
    MyDvHeader6,
    MyDvHeader5,
    MyDvMenu,
    MyDvTitle,
    MyDvText,
    MyDvDate,
    MyDvBox,
    MyDvAdorn5,
    MyDvAdorn3,
    MyDvAdorn1,
  },
})
export default class extends Mixins(MyCommon) {
  roboticon: string = icon.robot.replace('image://', '')

  title = '羽约运动全国场馆分布图'

  menus = [
    { text: '场馆', to: '/v3/' },
    { text: '用户', to: '/v3/user' },
    { text: '运营', to: '/v3/operation' },
    { text: '订单', to: '/v3/order' },
  ]

  defaultMenuActive = 0

  changeMenu(item: any, index: number) {
    this.defaultMenuActive = index
    if (index === 1) {
      // this.title = '用户'
    } else if (index === 2) {
      this.title = '小羽服务'
    } else {
      this.title = '羽约运动全国场馆分布图'
    }
  }

  mounted() {
    const { path } = this.$route

    console.log(path)
    if (path == '/v3/order') {
      this.defaultMenuActive = 3
    } else if (path == '/v3/operation') {
      this.defaultMenuActive = 2
    } else if (path == '/v3/user') {
      this.defaultMenuActive = 1
    } else {
      this.defaultMenuActive = 0
    }
  }
}
