import { render, staticRenderFns } from "./main.vue?vue&type=template&id=1b7e5c85&"
import script from "./main.vue?vue&type=script&lang=ts&"
export * from "./main.vue?vue&type=script&lang=ts&"
import style0 from "./main.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports